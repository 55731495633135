<script setup>
    import PrimevueBreadcrumb from 'primevue/breadcrumb';
    const { localeProperties } = useI18n();

    // Initialize i18n
    const localePath = useLocalePath();

    // Get WordPress URL to redirect when click on logo
    let homeUrl = 'https://stocklear.eu';

    if (localeProperties.value) {
        homeUrl = `${localeProperties.value.protocol}://${localeProperties.value.domain}`;
    }

    const props = defineProps({
        // List of routes to display in the breadcrumb
        model: {
            type: Object,
            required: true,
        },
    });

    const gtmMarketplaceButton = (url) => {
        if (url === localePath('index')) {
            // GTM Click - CTA - Marketplace
            dataLayer.push({
                event: 'generic_event',
                event_name: 'click_marketplace',
                button_id: 'breadcrumb',
                button_name: 'breadcrumb',
            });
        }
    };
</script>

<template>
    <PrimevueBreadcrumb :home="{ icon: 'ic:baseline-home', to: homeUrl }" :model="props.model">
        <template #item="slotProps">
            <NuxtLink
                :to="slotProps.item.to"
                class="p-menuitem-link"
                @click="gtmMarketplaceButton(slotProps.item.to)"
            >
                <Icon
                    v-if="slotProps.item.icon"
                    :name="slotProps.item.icon"
                    class="p-menuitem-icon"
                />

                <span v-if="slotProps.item.label" class="p-menuitem-text">
                    {{ slotProps.item.label }}
                </span>
            </NuxtLink>
        </template>
    </PrimevueBreadcrumb>
</template>
